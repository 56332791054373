import React from "react";

export const setStartDate = (
  newValue: any,
  userFilters: any,
  setUserFilters: any
) => {
  if (!userFilters.end_date) {
    setUserFilters((previousState: any) => {
      return { ...previousState, start_date: newValue };
    });
  }
  if (userFilters.end_date) {
    if (newValue <= userFilters.end_date) {
      setUserFilters((previousState: any) => {
        return { ...previousState, start_date: newValue };
      });
    }
    if (newValue > userFilters.end_date) {
      alert("Start date cannot be after end date");
    }
  }
};
