import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { NavigateFunction } from "react-router-dom";
import { AlertData } from "./AlertsUtils/alertsInterfaces";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { getAlertsWithQuerry } from "./AlertsUtils/getAlertsWithQuerry";

export const isCurrentDate = (selectedDate: string): boolean => {
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toDateString(); // Get the current date without time

  const selectedDateObj = new Date(selectedDate);
  const formattedSelectedDate = selectedDateObj.toDateString(); // Get the selected date without time

  return formattedSelectedDate === formattedCurrentDate;
};

export const EndDateDropDownAlerts = ({
  userFilters,
  setUserFilters,
  navigate,
  setFetchingFilteredData,
  setAlertData,
  setSelectedRow,
  setSeeMoreURL,
  setMP4URL,
  siteOptions,
  setSelectedButtonsFilled,
  setThumbnailVideos,
  setDateOutsideRange,
  setPage,
  setVideoClipLoading,
  setAlertsToDisplay,
  setPageLoading,
  setTimeFiltersDisabled,
  warningIssued,
  setWarningIssued,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  navigate: NavigateFunction;
  setFetchingFilteredData: Dispatcher<boolean>;
  setAlertData: Dispatcher<AlertData[]>;
  setSelectedRow: Dispatcher<any>;
  setSeeMoreURL: Dispatcher<string>;
  setMP4URL: Dispatcher<string>;
  siteOptions: any;
  setSelectedButtonsFilled: any;
  setThumbnailVideos: any;
  setDateOutsideRange: Dispatcher<boolean>;
  setPage: Dispatcher<number>;
  setVideoClipLoading: Dispatcher<boolean>;
  setAlertsToDisplay: Dispatcher<boolean>;
  setPageLoading: Dispatcher<boolean>;
  setTimeFiltersDisabled: Dispatcher<boolean>;
  warningIssued: boolean;
  setWarningIssued: Dispatcher<boolean>;
}) => {
  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  const parentIds = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="End Date"
        value={userFilters.end_date}
        onChange={(newValue: any) => {
          if (!warningIssued && !isCurrentDate(newValue)) {
            alert(
              `Selecting any dates for start date or end date which are not the current date will prevent new alerts from being refreshed in the background`
            );
            setWarningIssued(true);
          }
          if (newValue !== null) {
            if (userFilters.start_date && newValue < userFilters.start_date) {
              alert("End date cannot be before start date");
              return;
            }
            setUserFilters((previousState: any) => ({
              ...previousState,
              end_date: newValue,
            }));
          }
          if (newValue === null) {
            setUserFilters((previousState: any) => {
              return { ...previousState, end_date: null };
            });
          }
          if (
            yyyymmddGenerator(newValue) !==
            yyyymmddGenerator(userFilters.start_date)
          ) {
            setTimeFiltersDisabled(true);
            setUserFilters((previousState: any) => {
              return { ...previousState, start_time: null, end_time: null };
            });
          }
          if (
            yyyymmddGenerator(newValue) ===
            yyyymmddGenerator(userFilters.start_date)
          ) {
            setTimeFiltersDisabled(false);
          }
        }}
        renderInput={(params) => <TextField sx={{ width: 150 }} {...params} />}
      />
    </LocalizationProvider>
  );
};
