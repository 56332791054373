import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import useWindowDimensions from "./useWindowDimensions";

export const UserLink = (props: SvgIconProps) => {
  const userName = localStorage.username;
  const firstName = localStorage.first_name;
  const lastName = localStorage.last_name;
  const fullName = `${firstName} ${lastName}`;
  let userLink;

  const { height } = useWindowDimensions();

  if (firstName && lastName && fullName.length < 13) {
    userLink = (
      <p
        className={
          height > 485
            ? "absolute bottom-[35px] text-white text-[14.5px] font-mulish indent-3 font-normal"
            : "absolute top-[427px] text-white text-[14.5px] font-mulish indent-3 font-normal"
        }
      >
        {" "}
        <SvgIcon {...props}>
          <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88a9.947 9.947 0 0 1 12.28 0C16.43 19.18 14.03 20 12 20z"
          />
        </SvgIcon>
        &nbsp;
        {fullName}
      </p>
    );
  } else if (firstName && lastName && fullName.length < 20) {
    userLink = (
      <p
        className={
          height > 485
            ? "absolute bottom-[35px] text-white text-[16px] font-mulish indent-3 font-normal"
            : "absolute top-[427px] text-white text-[16px] font-mulish indent-3 font-normal"
        }
      >
        {" "}
        <SvgIcon {...props}>
          <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88a9.947 9.947 0 0 1 12.28 0C16.43 19.18 14.03 20 12 20z"
          />
        </SvgIcon>
        &nbsp;
        {fullName}
      </p>
    );
  } else if (
    firstName &&
    lastName &&
    fullName.length > 20 &&
    userName.length < 21
  ) {
    userLink = (
      <p className="absolute bottom-[35px] text-white text-[10px] font-mulish indent-3 font-normal">
        <SvgIcon {...props}>
          <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88a9.947 9.947 0 0 1 12.28 0C16.43 19.18 14.03 20 12 20z"
          />
        </SvgIcon>
        &nbsp;
        {userName}
      </p>
    );
  } else if (!firstName && !lastName && userName.length < 21) {
    userLink = (
      <p className="absolute bottom-[35px] text-white text-[10px] font-mulish indent-3 font-normal">
        <SvgIcon {...props}>
          <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88a9.947 9.947 0 0 1 12.28 0C16.43 19.18 14.03 20 12 20z"
          />
        </SvgIcon>
        &nbsp;
        {userName}
      </p>
    );
  } else {
    userLink = (
      <p className="absolute bottom-[35px] text-white text-[16px] font-mulish indent-3 font-normal">
        <SvgIcon {...props}>
          <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88a9.947 9.947 0 0 1 12.28 0C16.43 19.18 14.03 20 12 20z"
          />
        </SvgIcon>
        &nbsp; User
      </p>
    );
  }
  return userLink;
};
