import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getAlertsWithQuerry } from "./AlertsUtils/getAlertsWithQuerry";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { AlertData } from "./AlertsUtils/alertsInterfaces";

export const StartTimeDropDownAlerts = ({
  userFilters,
  setUserFilters,
  navigate,
  setFetchingFilteredData,
  setAlertData,
  setSelectedRow,
  setSeeMoreURL,
  setMP4URL,
  siteOptions,
  setSelectedButtonsFilled,
  setThumbnailVideos,
  setDateOutsideRange,
  setPage,
  setVideoClipLoading,
  setAlertsToDisplay,
  setPageLoading,
  timeFiltersDisabled,
}: {
  userFilters: any;
  setUserFilters: any;
  navigate: NavigateFunction;
  setFetchingFilteredData: Dispatcher<boolean>;
  setAlertData: Dispatcher<AlertData[]>;
  setSelectedRow: Dispatcher<any>;
  setSeeMoreURL: Dispatcher<string>;
  setMP4URL: Dispatcher<string>;
  siteOptions: any;
  setSelectedButtonsFilled: any;
  setThumbnailVideos: any;
  setDateOutsideRange: Dispatcher<boolean>;
  setPage: Dispatcher<number>;
  setVideoClipLoading: Dispatcher<boolean>;
  setAlertsToDisplay: Dispatcher<boolean>;
  setPageLoading: Dispatcher<boolean>;
  timeFiltersDisabled: boolean;
}) => {
  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  const parentIds = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        label="Start Time"
        value={userFilters.start_time}
        onChange={(newValue) => {
          if (userFilters.end_time && newValue > userFilters.end_time) {
            alert("Start time must be before end time");
            return;
          }
          setUserFilters((previousState: any) => ({
            ...previousState,
            start_time: newValue,
          }));
        }}
        disabled={timeFiltersDisabled}
        renderInput={(params) => <TextField sx={{ width: 150 }} {...params} />}
      />
    </LocalizationProvider>
  );
};
