import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const getAllCameras = (
  customerId: number,
  cameraId: number,
  setAllCamerasData: any,
  setCameraIndex: any,
  setBackdropLoadingScreenOn: Dispatcher<boolean>
) => {
  const AxiosUI = createApi(``);

  let allSiteCameras: any;

  const getSiteCameras = (pageNumber: number) => {
    AxiosUI.get(
      `camera/site/?customer__id=${customerId}&page=${pageNumber}`
    ).then(
      (response: AxiosResponse) => {
        if (response.data.count > 0) {
          if (pageNumber === 1) {
            allSiteCameras = response.data.results;
          }
          if (pageNumber > 1) {
            allSiteCameras.push(...response.data.results);
          }
          if (response.data.next) {
            getSiteCameras(pageNumber + 1);
          } else {
            setAllCamerasData(allSiteCameras);
            if (response.data.results.length > 0) {
              allSiteCameras.forEach((camera: any, index: number) => {
                if (camera.id === cameraId) {
                  setCameraIndex(index);
                  localStorage.setItem(
                    "currentCamera",
                    allSiteCameras[index].camera_name
                  );
                  localStorage.setItem(
                    "currentCameraId",
                    allSiteCameras[index].id
                  );
                }
              });
            } else {
              localStorage.setItem(
                "currentCamera",
                allSiteCameras[0].camera_name
              );
              localStorage.setItem("currentCameraId", allSiteCameras[0].id);
            }
            setBackdropLoadingScreenOn(false);
          }
        } else {
          setAllCamerasData(response.data.results);
          setBackdropLoadingScreenOn(false);
        }
      },
      (reason: AxiosError) => {
        setAllCamerasData([]);
        setBackdropLoadingScreenOn(false);
      }
    );
  };

  getSiteCameras(1);
};
