import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { fetchAddCameraInfo } from "../../Sites/SitesPage/SitesPageUtils/fetchAddCameraInfo";
import { createStreamsObject } from "./createStreamsObject";
import { matchAllStreams } from "./numericallySortAlerts";
import { IgnoreZonesBreadCrumbs } from "../../EditIgnoreZones/IgnoreZonesBreadCrumbs";
import { getAxisData } from "./getAxisData";
import { getMobotixData } from "./getMobotixData";
import { generateActiveProducts } from "./generateActiveProducts";
import { GeneralInfoEditCameraTab } from "../GeneralInfoEditCameraTab";
import { CameraInfoRow } from "../../Sites/SiteAboutPage/CameraInfoRow";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { set } from "date-fns";
import { getAllCameras } from "./getAllCameras";
import { getNumbersAfterEditCamera, getNumbersAfterSites } from "../EditCamera";

export const getEditCameraData = async (
  navigate: any,
  siteId: any,
  cameraId: any,
  setSiteInfo: any,
  setCameraGeneralInfo: any,
  setCameraObject: any,
  setCameraDataLoaded: any,
  setBackdropLoadingScreenOn: any,
  setImmixAlertsOnCamera: any,
  setIgnoreZonesBreadCrumbs: any,
  createIgnoreZonesLinksArray: any,
  handleSitesBreadcrumbClick: any,
  handleSingularSiteBreadcrumbClick: any,
  setSureViewAlertsOnCamera: any,
  setAxisCamera: any,
  setMobotixFinished: any,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  setActionRulesData: any,
  setActionRulesTitlesArray: any,
  setApplicationsConfiguredData: any,
  mobotixFinished: any,
  setAxisFinished: any,
  axisFinished: any,
  setMobotixCamera: any,
  setTimeTablesData: any,
  setIPNotifyData: any,
  setActionHandlerData: any,
  setCameraGeneralInfoBackup: any,
  setImmixAlerts: any,
  setSureviewAlerts: any,
  setActiveProducts: any,
  setActiveTab: any,
  actionRulesTitlesArray: any,
  applicationsConfiguredData: any,
  cameraGeneralInfo: any,
  actionRulesData: any,
  setSrcImages: any,
  permissions: any,
  setCameraInfoRow: any,
  width: any,
  setCameraWebhookData: any,
  setProductsData: any,
  setBackupProductsData: any,
  setSentinelAlertsOnCamera: any,
  setSentinelAlerts: any,
  setBoldAlertsOnCamera: Dispatcher<boolean>,
  setBoldAlerts: Dispatcher<any>,
  setPatriotAlertsOnCamera: Dispatcher<boolean>,
  setPatriotAlerts: Dispatcher<any>,
  setUSMonitoringAlertsOnCamera: Dispatcher<boolean>,
  setUSMonitoringAlerts: Dispatcher<any>,
  setAllCamerasData: any,
  setCameraIndex: any
) => {
  localStorage.setItem("siteId", siteId);
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    getEditCameraData(
      navigate,
      siteId,
      cameraId,
      setSiteInfo,
      setCameraGeneralInfo,
      setCameraObject,
      setCameraDataLoaded,
      setBackdropLoadingScreenOn,
      setImmixAlertsOnCamera,
      setIgnoreZonesBreadCrumbs,
      createIgnoreZonesLinksArray,
      handleSitesBreadcrumbClick,
      handleSingularSiteBreadcrumbClick,
      setSureViewAlertsOnCamera,
      setAxisCamera,
      setMobotixFinished,
      setRecipientsConfiguredData,
      setTitlesArray,
      setActionRulesData,
      setActionRulesTitlesArray,
      setApplicationsConfiguredData,
      mobotixFinished,
      setAxisFinished,
      axisFinished,
      setMobotixCamera,
      setTimeTablesData,
      setIPNotifyData,
      setActionHandlerData,
      setCameraGeneralInfoBackup,
      setImmixAlerts,
      setSureviewAlerts,
      setActiveProducts,
      setActiveTab,
      actionRulesTitlesArray,
      applicationsConfiguredData,
      cameraGeneralInfo,
      actionRulesData,
      setSrcImages,
      permissions,
      setCameraInfoRow,
      width,
      setCameraWebhookData,
      setProductsData,
      setBackupProductsData,
      setSentinelAlertsOnCamera,
      setSentinelAlerts,
      setBoldAlertsOnCamera,
      setBoldAlerts,
      setPatriotAlertsOnCamera,
      setPatriotAlerts,
      setUSMonitoringAlertsOnCamera,
      setUSMonitoringAlerts,
      setAllCamerasData,
      setCameraIndex
    );
  };
  let getSiteInfoFail = false;
  localStorage.setItem("noAutoAddCamerasDialogOpen", "true");

  let generalInfo: any = {};

  const alarmOptions = JSON.parse(localStorage.alarmOptions);

  const alarmIds = new Map();
  alarmOptions.forEach((object: any) => {
    alarmIds.set(object.value, object.name);
  });

  let tempSiteInfo = {};

  await AxiosUI.get(`customer/${siteId}/about/`).then(
    (response: AxiosResponse) => {
      getSiteInfoFail = false;
      fetchAddCameraInfo(siteId, navigate);
      setSiteInfo(response.data);
      tempSiteInfo = response.data;
      generalInfo = response.data;
      setIgnoreZonesBreadCrumbs(
        <IgnoreZonesBreadCrumbs
          linksArray={createIgnoreZonesLinksArray(response.data.breadcrumbs)}
          handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
          handleSingularSiteBreadcrumbClick={handleSingularSiteBreadcrumbClick}
          styling="absolute top-[10px] left-[30px]"
        />
      );
      localStorage.setItem("integrationType", response.data.integration_type);
      if (response.data.auto_add) {
        localStorage.setItem("autoAdd", "true");
      } else {
        localStorage.setItem("autoAdd", "false");
      }
    },
    (reason: AxiosError) => {
      getSiteInfoFail = true;
      if (reason.request.response.includes(`Not found`)) {
        navigate("/*");
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Error retreiving site data");
          navigate("/dashboard");
          // eslint-disable-next-line no-console
          console.log(`${reason}`);
          localStorage.setItem("autoAdd", "true");
        });
      }
    }
  );
  !getSiteInfoFail &&
    (await AxiosUI.get(`/camera/${cameraId}/general_info/`).then(
      (response: any) => {
        const cameraObject = response.data;
        localStorage.setItem("currentCamera", response.data.camera_name);
        setCameraGeneralInfo(cameraObject);
        localStorage.setItem(
          "currentCameraInfo",
          JSON.stringify(response.data)
        );
        setCameraObject(cameraObject);
        if (alarmIds.get(cameraObject.default_alarm) === "Immix") {
          setImmixAlertsOnCamera(true);
        }
        if (alarmIds.get(cameraObject.default_alarm) === "SureView") {
          setSureViewAlertsOnCamera(true);
        }
        if (alarmIds.get(cameraObject.default_alarm) === "Sentinel") {
          setSentinelAlertsOnCamera(true);
        }
        if (cameraObject.streams[0]) {
          cameraObject.streams.forEach((obj: any) => {
            if (obj.bold_alerts[0]) {
              setBoldAlertsOnCamera(true);
            }
            // if (obj.patriot_alerts[0]) {
            //   setPatriotAlertsOnCamera(true);
            // }
            // if (obj.us_monitoring_alerts[0]) {
            //   setUSMonitoringAlertsOnCamera(true);
            // }
            if (obj.sureview_alerts[0]) {
              setSureViewAlertsOnCamera(true);
            }
            if (obj.immix_alerts[0]) {
              setImmixAlertsOnCamera(true);
              //make a clone object of response
              if (obj.immix_alerts.length > 1) {
                const newSortedObect = cameraObject;
                //make a clone streams of clone object and run numericallySortAlerts on the clone stream object
                const newSortedStreams = matchAllStreams(
                  newSortedObect.streams,
                  "immix_alerts"
                );
                //reassign clone object's streams property to the clone streams
                newSortedObect.streams = newSortedStreams;
                //store the clone object in local storage
                setCameraGeneralInfo(newSortedObect);
              }
            }
            if (obj.sureview_alerts[0]) {
              //make a clone object of response
              if (obj.sureview_alerts.length > 1) {
                const newSortedObect = cameraObject;
                //make a clone streams of clone object and run numericallySortAlerts on the clone stream object
                const newSortedStreams = matchAllStreams(
                  newSortedObect.streams,
                  "sureview_alerts"
                );
                //reassign clone object's streams property to the clone streams
                newSortedObect.streams = newSortedStreams;
                //store the clone object in local storage
                setCameraGeneralInfo(newSortedObect);
              }
            }
          });
        }
        if (cameraObject.camera_type) {
          if (cameraObject.camera_type.display_name === "Axis") {
            setAxisCamera(true);
            setMobotixFinished({
              timeTable: true,
              iPNotify: true,
              actionHandler: true,
            });
            getAxisData(
              cameraObject.id,
              navigate,
              setRecipientsConfiguredData,
              setTitlesArray,
              setActionRulesData,
              setActionRulesTitlesArray,
              setApplicationsConfiguredData,
              mobotixFinished,
              setAxisFinished,
              setBackdropLoadingScreenOn,
              axisFinished,
              setActiveTab,
              actionRulesTitlesArray,
              applicationsConfiguredData,
              cameraGeneralInfo,
              actionRulesData
            );
          } else if (cameraObject.camera_type.display_name === "Mobotix") {
            setMobotixCamera(true);
            setAxisFinished({
              recipient: true,
              actionRules: true,
              application: true,
            });
            getMobotixData(
              cameraObject.id,
              navigate,
              setTimeTablesData,
              setBackdropLoadingScreenOn,
              setIPNotifyData,
              setActionHandlerData,
              axisFinished,
              mobotixFinished,
              setMobotixFinished
            );
          }
        }
        const connectionsTitlesArray: any[] = [];
        if (cameraObject.ip) {
          connectionsTitlesArray.push("Host");
        }
        if (cameraObject.rtsp) {
          connectionsTitlesArray.push("RTSP");
        }
        if (cameraObject.http) {
          connectionsTitlesArray.push("HTTP Port");
        }
        if (cameraObject.server_port) {
          connectionsTitlesArray.push("Server Port");
        }
        if (cameraObject.username) {
          connectionsTitlesArray.push("Username");
        }
        if (cameraObject.password) {
          connectionsTitlesArray.push("Password");
        }
        if (cameraObject.channel) {
          connectionsTitlesArray.push("Channel");
        }
        if (cameraObject.stream_parameters) {
          connectionsTitlesArray.push("Stream Parameters");
        }
        if (cameraObject.slice) {
          connectionsTitlesArray.push("Slice");
        }
        if (cameraObject.split) {
          connectionsTitlesArray.push("Split");
        }
        if (cameraObject.use_stream_quality) {
          connectionsTitlesArray.push("Stream Quality");
        }
        localStorage.setItem(
          "connectionsTitlesArray",
          JSON.stringify(connectionsTitlesArray)
        );
        setCameraGeneralInfoBackup(cameraObject);
        const pushedArray: any[] = [];
        if (cameraObject.streams[0]) {
          if (cameraObject.streams[0].immix_alerts[0]) {
            cameraObject.streams[0].immix_alerts.forEach((object: any) => {
              pushedArray.push(object);
            });
          }
          if (cameraObject.streams[0].sentinel_alerts[0]) {
            cameraObject.streams[0].sentinel_alerts.forEach((object: any) => {
              pushedArray.push(object);
            });
          }
        }
        setImmixAlerts(pushedArray);
        setSentinelAlerts(pushedArray);
        if (cameraObject.streams[0]) {
          cameraObject.streams.forEach((streamObject: any) => {
            if (streamObject.sureview_alerts[0]) {
              localStorage.setItem(
                "sureViewStreamObject",
                JSON.stringify(streamObject)
              );
            } else if (streamObject.immix_alerts[0]) {
              localStorage.setItem(
                "immixStreamObject",
                JSON.stringify(streamObject)
              );
            }
          });
        }

        const sureViewPushedArray: any[] = [];
        if (cameraObject.streams[0]) {
          if (cameraObject.streams[0].sureview_alerts[0]) {
            cameraObject.streams[0].sureview_alerts.forEach((object: any) => {
              sureViewPushedArray.push(object);
            });
          }
        }
        setSureviewAlerts(sureViewPushedArray);

        const boldPushedArray: any[] = [];
        if (cameraObject.streams[0]) {
          if (cameraObject.streams[0].bold_alerts[0]) {
            cameraObject.streams[0].bold_alerts.forEach((object: any) => {
              boldPushedArray.push(object);
            });
          }
        }

        setBoldAlerts(boldPushedArray);

        // const patriotPushedArray: any[] = [];
        // if (cameraObject.streams[0]) {
        //   if (cameraObject.streams[0].patriot_alerts[0]) {
        //     cameraObject.streams[0].patriot_alerts.forEach((object: any) => {
        //       patriotPushedArray.push(object);
        //     });
        //   }
        // }

        // setPatriotAlerts(patriotPushedArray);

        // const usMonitoringPushedArray: any[] = [];
        // if (cameraObject.streams[0]) {
        //   if (cameraObject.streams[0].us_monitoring_alerts[0]) {
        //     cameraObject.streams[0].us_monitoring_alerts.forEach((object: any) => {
        //       usMonitoringPushedArray.push(object);
        //     });
        //   }
        // }

        // setUSMonitoringAlerts(usMonitoringPushedArray);

        const streamsObject = createStreamsObject(cameraObject.streams);

        generateActiveProducts(
          streamsObject,
          setActiveProducts,
          setProductsData,
          setBackupProductsData
        );

        setActiveTab(
          <GeneralInfoEditCameraTab
            siteInfo={generalInfo}
            cameraGeneralInfo={cameraObject}
          />
        );
        AxiosUI.get(`/camera/${cameraObject.id}/status/`).then(
          (response: AxiosResponse) => {
            const name = `${cameraObject.id}full`;
            setSrcImages((previousState: any) => {
              return {
                ...previousState,
                [cameraObject.id]: (
                  <img src={response.data.preview_url} alt="site_image" />
                ),
                [name]: response.data,
              };
            });
            setCameraInfoRow(
              <CameraInfoRow
                obj={cameraObject}
                expanded={true}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                screenWidth={width}
                siteInfo={tempSiteInfo}
                permissions={permissions}
                srcImages={{
                  [cameraObject.id]: (
                    <img src={response.data.preview_url} alt="site_image" />
                  ),
                  [name]: response.data,
                }}
              />
            );
            AxiosUI.get(`/webhook/?stream__camera__id=${cameraObject.id}`).then(
              (response: AxiosResponse) => {
                if (response.data.count > 0) {
                  setCameraWebhookData(response.data.results[0]);
                }
              },
              (reason: AxiosError) => {
                universalAPIErrorHandler(
                  reason,
                  navigate,
                  retryFunction,
                  () => {}
                );
              }
            );
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
          }
        );
        setCameraDataLoaded(true);
        getAllCameras(
          Number(getNumbersAfterSites(location.href)),
          Number(getNumbersAfterEditCamera(location.href)),
          setAllCamerasData,
          setCameraIndex,
          setBackdropLoadingScreenOn
        );
      },
      (reason: AxiosError) => {
        if (reason.request.response.includes(`Not found`)) {
          navigate("/*");
        } else {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert("Error retreiving site data");
            navigate("/");
          });
        }
      }
    ));
};
