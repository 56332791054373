import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { Typography } from "@mui/material";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import EditIcon from "@mui/icons-material/Edit";

export const WebhooksCameraTab = ({
  cameraWebhookData,
  setAddStreamWebhookDialogOpen,
  setEditStreamWebhookDialogOpen,
}: {
  cameraWebhookData: any;
  setAddStreamWebhookDialogOpen: any;
  setEditStreamWebhookDialogOpen: any;
}) => {
  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  const titlesArray = ["Webhook", "Custom Fields", "Event Type"];

  const handleConvertTypeToString = (string: any) => {
    if (string === "no_motion") {
      return "No Motion";
    } else if (string === "unable_to_connect") {
      return "Unable to Connect";
    } else if (string === "detection") {
      return "Detection";
    } else return "";
  };

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      <div className="min-w-[1150px] h-[50px] bg-white">
        <div className="absolute right-[70px] top-[10px]">
          {permissions.edit_cameras && (
            <OrangeTextButton
              onClick={() => {
                setAddStreamWebhookDialogOpen(true);
              }}
              text="+ Add Stream Webhook"
              disabled={false}
            />
          )}
        </div>
      </div>
      {cameraWebhookData[0] &&
        cameraWebhookData.map((object: any, index: any) => {
          return (
            <div key={object.id} className="h-[188px]">
              <div className="min-w-[1150px] h-[60px] bg-white">
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "black",
                    textIndent: "1vw",
                    paddingTop: "10px",
                  }}
                >
                  Stream Webhook {index + 1}
                  <OrangeTextButton
                    onClick={() => {
                      localStorage.setItem(
                        "selectedWebhook",
                        JSON.stringify(object)
                      );
                      setEditStreamWebhookDialogOpen(true);
                    }}
                    text={
                      <>
                        <EditIcon fontSize="small" />
                      </>
                    }
                    disabled={false}
                  />
                </Typography>
              </div>
              <div>
                <TableContainer component={Paper} sx={{ width: "screen" }}>
                  <Table
                    sx={{
                      postion: "absolute",
                      width: "screen",
                      fontFamily: "Mulish",
                    }}
                  >
                    <TableHeadComponent titlesArray={titlesArray} />
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.webhook.display_name
                            ? object.webhook.display_name
                            : ""}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.custom_fields
                            ? `${JSON.stringify(object.custom_fields)}`
                            : ""}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.event_types
                            ? object.event_types[0]
                              ? //map object.event_types and return each one using handleConvertTypeToString seperarted by a comma
                              object.event_types.map((eventType: any) => {
                                return `${handleConvertTypeToString(
                                  eventType
                                )}, `;
                              })
                              : ""
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          );
        })}
    </div>
  );
};
