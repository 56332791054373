{
  "name": "actuate-camera-ui",
  "version": "4.3.61",
  "description": "",
  "scripts": {
    "dev": "parcel src/index.html",
    "build": "parcel build src/index.html",
    "format": "prettier --write \"src/**/*.{js,jsx,ts,tsx}\"",
    "lint": "eslint \"src/**/*.{js,jsx,ts,tsx}\" --quiet"
  },
  "author": "Actuate AI",
  "license": "Apache-2.0",
  "devDependencies": {
    "@parcel/transformer-sass": "^2.6.0",
    "@types/file-saver": "^2.0.7",
    "@types/mui-image": "^1.0.1",
    "@types/node": "^17.0.35",
    "@types/react": "^17.0.39",
    "@types/react-dom": "^17.0.11",
    "@types/react-virtualized": "^9.21.21",
    "@types/react-window": "^1.8.5",
    "@types/tailwindcss": "^3.1.0",
    "@typescript-eslint/eslint-plugin": "^5.26.0",
    "@typescript-eslint/parser": "^5.27.0",
    "assert": "^2.0.0",
    "autoprefixer": "^10.4.2",
    "buffer": "^6.0.3",
    "console-browserify": "^1.2.0",
    "eslint": "8.8.0",
    "eslint-config-prettier": "8.3.0",
    "eslint-import-resolver-typescript": "^2.7.1",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-jsx-a11y": "^6.5.1",
    "eslint-plugin-react": "^7.28.0",
    "eslint-plugin-react-hooks": "^4.3.0",
    "parcel": "2.2.1",
    "postcss": "^8.4.6",
    "prettier": "2.5.1",
    "process": "^0.11.10",
    "querystring-es3": "^0.2.1",
    "tailwindcss": "^3.3.1",
    "typescript": "^4.5.5",
    "util": "^0.12.4"
  },
  "dependencies": {
    "@emotion/react": "^11.13.0",
    "@emotion/styled": "^11.13.0",
    "@excalidraw/excalidraw": "^0.12.0",
    "@mui/icons-material": "^5.8.3",
    "@mui/material": "^5.16.6",
    "@mui/x-data-grid": "^5.13.4",
    "@mui/x-date-pickers": "^5.0.0-beta.4",
    "axios": "^0.27.2",
    "chart.js": "^4.2.1",
    "cookie-parser": "^1.4.6",
    "date-fns": "^2.29.1",
    "dayjs": "^1.11.9",
    "env-cmd": "^10.1.0",
    "file-saver": "^2.0.5",
    "formik": "^2.2.9",
    "js-file-download": "^0.4.12",
    "local-storage": "^2.0.0",
    "lodash": "^4.17.21",
    "lodash.debounce": "^4.0.8",
    "material-ui-popup-state": "^5.0.9",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.43",
    "react": "^17.0.2",
    "react-chartjs-2": "^5.2.0",
    "react-cookie": "^4.1.1",
    "react-dom": "^17.0.2",
    "react-hotkeys": "^2.0.0",
    "react-infinite-scroll-component": "^6.1.0",
    "react-moment": "^1.1.3",
    "react-player": "^2.16.0",
    "react-router-dom": "^6.3.0",
    "react-window": "^1.8.7",
    "recharts": "^2.4.3",
    "svg": "^0.1.0",
    "universal-cookie": "^4.0.4",
    "video-react": "^0.16.0",
    "yup": "^0.32.11",
    "zustand": "^4.5.1"
  },
  "browserslist": [
    "last 2 Chrome versions"
  ]
}
