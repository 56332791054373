/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../utils/themeCreator";
import { Button } from "@mui/material";
import { checkedCircleIcon } from "../../../common/checkedCircleIcon";
import { cancelIconRed } from "../../../common/cancelIconRed";
import { handleCamerasTableChangeRowsPerPage } from "./SiteAboutPageUtils/handleCamerasTableChangeRowsPerPage";
import { handleCamerasTableChangePage } from "./SiteAboutPageUtils/handleCamerasTableChangePage";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CameraInfoRow } from "./CameraInfoRow";
import Checkbox from "@mui/material/Checkbox";
import { DuplicateCameraSiteButton } from "./DuplicateCameraButton";
import { ActivateButton } from "./ActivateButton";
import { DeactivateButton } from "./DeactivateButton";
import { DeleteCameraButton } from "./DeleteCameraButton";
import useWindowDimensions from "../../../common/useWindowDimensions";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { getAndSaveSiteImages } from "./SiteAboutPageUtils/getAndSaveSiteImages";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import {
  AlertState,
  SiteCameras,
  SiteCamerasResult,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { EnhancedTableProps } from "./SiteCameras";
import { EnhancedCameraTableHead } from "./EnhancedCameraTableHead";
import { AlertData, Order } from "../../Alerts/AlertsUtils/alertsInterfaces";
import {
  stableSort,
  getComparator,
} from "../../Alerts/AlertsUtils/alertsTableFunctions";
import SyncIcon from "@mui/icons-material/Sync";
import { handleRefreshCameras } from "./SiteAboutPageUtils/handleRefreshCameras";
import { createOptimalSettingsIcon } from "./SiteAboutPageUtils/createOptimalSettingsIcon";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const CamerasTable = ({
  navigate,
  EnhancedTableHead,
  siteCameras,
  page,
  rowsPerPage,
  isSelected,
  setPage,
  setRowsPerPage,
  handleSelectAllClick,
  handleSingleCheckboxClick,
  selected,
  setSiteCameras,
  setDeleteDialogOpen,
  setAlertState,
  setSelected,
  setSyncNeeded,
  setBackdropLoadingScreenOn,
  setNoAutoAddCamerasDialogOpen,
  siteInfo,
  setSiteInfo,
  setSiteSchedules,
  setActiveTab,
  setDataLoaded,
  setAllSiteSchedules,
  setVmsVariables,
  setArmedStatus,
  setAiLinkSite,
  setSiteDeployed,
  setCamerasNotDeployed,
  setToolTipTitle,
  setConfigureMotion,
  setPatchObject,
  setArmButtonDisabled,
  setRecordingServerData,
  setRecordingServerPatchObject,
  setRecordingServerVisible,
  setHealthCheckData,
  setCamerasRefreshing,
  permissions,
  setWebhooksInfo,
  setWebhooksPatchObject,
}: {
  navigate: NavigateFunction;
  EnhancedTableHead: (props: EnhancedTableProps) => JSX.Element;
  siteCameras: SiteCameras;
  page: number;
  rowsPerPage: number;
  isSelected: (id: string) => boolean;
  setPage: Dispatcher<number>;
  setRowsPerPage: Dispatcher<number>;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSingleCheckboxClick: (
    event: React.MouseEvent<unknown>,
    id: string
  ) => void;
  selected: readonly string[];
  setSiteCameras: Dispatcher<SiteCameras>;
  setDeleteDialogOpen: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setSelected: Dispatcher<readonly string[]>;
  setSyncNeeded: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setNoAutoAddCamerasDialogOpen: Dispatcher<boolean>;
  siteInfo: any;
  setSiteInfo: any;
  setSiteSchedules: any;
  setActiveTab: any;
  setDataLoaded: any;
  setAllSiteSchedules: any;
  setVmsVariables: any;
  setArmedStatus: any;
  setAiLinkSite: any;
  setSiteDeployed: any;
  setCamerasNotDeployed: any;
  setToolTipTitle: any;
  setConfigureMotion: any;
  setPatchObject: any;
  setArmButtonDisabled: any;
  setRecordingServerData: any;
  setRecordingServerPatchObject: any;
  setRecordingServerVisible: Dispatcher<boolean>;
  setHealthCheckData: any;
  setCamerasRefreshing: Dispatcher<boolean>;
  permissions: any;
  setWebhooksInfo: any;
  setWebhooksPatchObject: any;
}) => {
  const [expandedObject, setExpandedObject] = React.useState({});

  const { width } = useWindowDimensions();

  const [srcImages, setSrcImages] = React.useState({});

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("formatted_date");

  const [renderedRows, setRenderedRows] = React.useState(siteCameras.results);

  const [allExpanded, setAllExpanded] = React.useState(
    //check localStorage.storedExpandedObject and if all values are true, set allExpanded to true, else set it to false
    // () => {
    //   if (!localStorage.storedExpandedObject) {
    //     return false;
    //   } else {
    //     const storedExpandedObject = JSON.parse(
    //       localStorage.storedExpandedObject
    //     );
    //     const allTrue = Object.values(storedExpandedObject).every(
    //       (val) => val === true
    //     );
    //     return allTrue;
    //   }
    // }
    false
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const saveExpandedObject = (
    newObject: { [key: string]: boolean },
    objectId: string
  ) => {
    const newExpandedObj: { [key: string]: boolean } = expandedObject;
    const newValue = !expandedObject[objectId as keyof typeof expandedObject];
    newExpandedObj[objectId] = newValue;
    localStorage.setItem("storedExpandedObject", JSON.stringify(newObject));
    localStorage.setItem("expandedObjectSaved", "true");
  };

  const saveExpandedObjectAllTrue = () => {
    const newExpandedObj: { [key: string]: boolean } = expandedObject;
    Object.keys(newExpandedObj).forEach((key) => {
      newExpandedObj[key] = true;
    });
    localStorage.setItem(
      "storedExpandedObject",
      JSON.stringify(newExpandedObj)
    );
    localStorage.setItem("expandedObjectSaved", "true");
  };

  const saveExpandedObjectAllFalse = () => {
    const newExpandedObj: { [key: string]: boolean } = expandedObject;
    Object.keys(newExpandedObj).forEach((key) => {
      newExpandedObj[key] = false;
    });
    localStorage.setItem(
      "storedExpandedObject",
      JSON.stringify(newExpandedObj)
    );
    localStorage.setItem("expandedObjectSaved", "true");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    // if (localStorage.expandedObjectSaved === "true") {
    //   setExpandedObject(JSON.parse(localStorage.storedExpandedObject));
    // } else {
    siteCameras.results.forEach((object: SiteCamerasResult) => {
      setExpandedObject((previousState: { [key: string]: boolean }) => {
        return { ...previousState, [object.id]: false };
      });
    });
    // }
  }, []);

  React.useEffect(() => {
    setRenderedRows(siteCameras.results);
  }, [siteCameras, expandedObject]);

  React.useEffect(() => {
    getAndSaveSiteImages(siteCameras, setSrcImages, navigate);
  }, []);

  const nA = `N/A`;

  const visibleRows = React.useMemo(
    () =>
      stableSort(renderedRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage, renderedRows]
  );

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <div className="relative">
          <Typography
            sx={{
              fontFamily: "Mulish",
              fontWeight: "Bold",
              textIndent: "33px",
              paddingTop: "10px",
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Site Cameras
            <div className={"absolute right-9 top-[12px]"}>
              {siteInfo.integration_type_name !== "ailink" &&
                permissions.add_cameras && (
                  <ThemeProvider theme={theme}>
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        if (
                          siteInfo.auto_add &&
                          localStorage.noAutoAddCamerasDialogOpen === "true"
                        ) {
                          setNoAutoAddCamerasDialogOpen(true);
                        } else if (
                          siteInfo.auto_add &&
                          localStorage.noAutoAddCamerasDialogOpen !== "true"
                        ) {
                          localStorage.setItem("addAdditionalCamera", "true");
                          navigate("/sites/autoaddcamera");
                        } else {
                          localStorage.setItem("firstCameraAdded", "true");
                          localStorage.setItem("copyValues", "true");
                          localStorage.setItem("addAdditionalCamera", "true");
                          navigate("/sites/addcamera");
                        }
                      }}
                    >
                      + Add Camera
                    </Button>
                  </ThemeProvider>
                )}
            </div>
            {siteInfo.display_refresh && (
              <div className="absolute left-[160px] top-[12px]">
                <ThemeProvider theme={theme}>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      setBackdropLoadingScreenOn(true);
                      setExpandedObject({});
                      localStorage.setItem(
                        "storedExpandedObject",
                        JSON.stringify({})
                      );
                      setCamerasRefreshing(true);
                      handleRefreshCameras(
                        navigate,
                        setSiteInfo,
                        setSiteSchedules,
                        setSiteCameras,
                        setActiveTab,
                        setDataLoaded,
                        setBackdropLoadingScreenOn,
                        setAllSiteSchedules,
                        setVmsVariables,
                        setNoAutoAddCamerasDialogOpen,
                        setArmedStatus,
                        setAiLinkSite,
                        setSiteDeployed,
                        setSyncNeeded,
                        setAlertState,
                        setCamerasNotDeployed,
                        setToolTipTitle,
                        setConfigureMotion,
                        setPatchObject,
                        setArmButtonDisabled,
                        setRecordingServerData,
                        setRecordingServerPatchObject,
                        setRecordingServerVisible,
                        setHealthCheckData,
                        setCamerasRefreshing,
                        setWebhooksInfo,
                        setWebhooksPatchObject
                      );
                    }}
                  >
                    <SyncIcon fontSize="small" /> &nbsp; Refresh Camera Previews
                  </Button>
                </ThemeProvider>
              </div>
            )}
          </Typography>
        </div>
        <div className="pt-2">
          <span className="relative left-6">
            {permissions.add_cameras && (
              <>
                <DuplicateCameraSiteButton
                  selected={selected}
                  setSiteCameras={setSiteCameras}
                  setAlertState={setAlertState}
                  setSelected={setSelected}
                  setSyncNeeded={setSyncNeeded}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            <ActivateButton
              selected={selected}
              setSiteCameras={setSiteCameras}
              setAlertState={setAlertState}
              setSelected={setSelected}
              setSyncNeeded={setSyncNeeded}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <DeactivateButton
              selected={selected}
              setSiteCameras={setSiteCameras}
              setAlertState={setAlertState}
              setSelected={setSelected}
              setSyncNeeded={setSyncNeeded}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            {permissions.delete_cameras && (
              <DeleteCameraButton
                selected={selected}
                setDeleteDialogOpen={setDeleteDialogOpen}
              />
            )}
          </span>
        </div>

        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          {/* <EnhancedTableHead
            onSelectAllClick={handleSelectAllClick}
            // rowCount={siteCameras.length}
          /> */}
          <EnhancedCameraTableHead
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
            permissions={permissions}
            allExpanded={allExpanded}
            setAllExpanded={setAllExpanded}
            setExpandedObject={setExpandedObject}
            saveExpandedObjectAllTrue={saveExpandedObjectAllTrue}
            saveExpandedObjectAllFalse={saveExpandedObjectAllFalse}
          />
          <TableBody>
            {visibleRows.map((obj: any) => {
              // const srcImageString = `${obj.id}full`;
              const isItemSelected = isSelected(obj.id);
              return (
                <React.Fragment key={obj.id}>
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    {permissions.edit_cameras && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleSingleCheckboxClick(event, obj.id)
                          }
                        />
                      </TableCell>
                    )}
                    <Tooltip
                      title={
                        <React.Fragment>
                          {srcImages[obj.id as keyof typeof srcImages]}
                        </React.Fragment>
                      }
                      placement="right-start"
                      sx={{ fontSize: "15px", fontFamily: "mulish" }}
                    >
                      <TableCell
                        sx={{ width: 300, fontFamily: "Mulish" }}
                        align="left"
                      >
                        {obj.camera_name}
                      </TableCell>
                    </Tooltip>
                    <TableCell align="left">
                      {obj.active ? checkedCircleIcon : cancelIconRed}
                    </TableCell>
                    <TableCell align="left">
                      {obj.deployed ? checkedCircleIcon : cancelIconRed}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 150 }}
                    >
                      {obj.use_motion ? obj.use_motion : nA}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 180 }}
                    >
                      {obj.has_ignore_zones ? checkedCircleIcon : cancelIconRed}
                    </TableCell>
                    {siteInfo.integration_type_name !== "ailink" ? (
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        {obj.health_status === "healthy" && checkedCircleIcon}
                        {obj.health_status === "error" && (
                          <Tooltip
                            title={
                              obj.camera_health_comment
                                ? obj.camera_health_comment
                                : "Unable to connect to the camera or no frame since launch"
                            }
                            placement="top"
                            sx={{ fontSize: "24px", fontFamily: "mulish" }}
                          >
                            <CancelIcon color="error" />
                          </Tooltip>
                        )}
                        {obj.health_status === "unknown" && (
                          <Tooltip
                            title={
                              obj.camera_health_comment
                                ? obj.camera_health_comment
                                : "Unable to determine the health status of this camera"
                            }
                            placement="top"
                            sx={{ fontSize: "24px", fontFamily: "mulish" }}
                          >
                            <WarningIcon sx={{ color: "#b4b4b4" }} />
                          </Tooltip>
                        )}
                        {obj.health_status === "warning" && (
                          <Tooltip
                            title={
                              obj.camera_health_comment
                                ? obj.camera_health_comment
                                : "Broken frames since launch or view is blurry"
                            }
                            placement="top"
                            sx={{ fontSize: "15px", fontFamily: "mulish" }}
                          >
                            <ErrorIcon sx={{ color: "#F57C00" }} />
                          </Tooltip>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <Tooltip title="Health can not be measured on a camera on this type of site (AI Link)">
                          <WarningIcon sx={{ color: "#b4b4b4" }} />
                        </Tooltip>
                      </TableCell>
                    )}
                    <TableCell
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 200 }}
                    >
                      {createOptimalSettingsIcon(srcImages, obj)}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          const objectId = obj.id;
                          setExpandedObject(
                            (previousState: { [key: string]: boolean }) => {
                              return {
                                ...previousState,
                                [objectId]:
                                  !expandedObject[
                                    objectId as keyof typeof expandedObject
                                  ],
                              };
                            }
                          );
                          saveExpandedObject(expandedObject, objectId);
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          {expandedObject[
                            obj.id as keyof typeof expandedObject
                          ] ? (
                            <Chip
                              icon={<KeyboardArrowUpIcon />}
                              label="Show Less"
                              color="secondary"
                              sx={{ fontFamily: "Mulish" }}
                            />
                          ) : (
                            <Chip
                              icon={<KeyboardArrowDownIcon />}
                              label="Show More"
                              color="secondary"
                              sx={{ fontFamily: "Mulish" }}
                            />
                          )}
                        </ThemeProvider>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <CameraInfoRow
                    obj={obj}
                    expanded={
                      expandedObject[obj.id as keyof typeof expandedObject]
                    }
                    setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                    screenWidth={width}
                    siteInfo={siteInfo}
                    permissions={permissions}
                    srcImages={srcImages}
                  />
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={siteCameras.results.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event: unknown, newPage: number) => {
          handleChangePage(event, newPage);
          const updatedSiteCamerasTablePagePreferences = JSON.parse(
            localStorage.siteCamerasTablePagePreferences
          );
          // if updatedSiteCamerasTablePagePreferences[siteInfo.id] is undefined, then set it to an object with the value of the current page and rowsPerPage
          if (!updatedSiteCamerasTablePagePreferences[siteInfo.id]) {
            updatedSiteCamerasTablePagePreferences[siteInfo.id] = {
              page: newPage,
              rowsPerPage: rowsPerPage,
            };
            localStorage.setItem(
              "siteCamerasTablePagePreferences",
              JSON.stringify(updatedSiteCamerasTablePagePreferences)
            );
          } else {
            updatedSiteCamerasTablePagePreferences[siteInfo.id].page = newPage;
            localStorage.setItem(
              "siteCamerasTablePagePreferences",
              JSON.stringify(updatedSiteCamerasTablePagePreferences)
            );
          }
        }}
        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChangeRowsPerPage(event);
          const updatedSiteCamerasTablePagePreferences = JSON.parse(
            localStorage.siteCamerasTablePagePreferences
          );
          if (!updatedSiteCamerasTablePagePreferences[siteInfo.id]) {
            updatedSiteCamerasTablePagePreferences[siteInfo.id] = {
              page: 0,
              rowsPerPage: parseInt(event.target.value, 10),
            };
            localStorage.setItem(
              "siteCamerasTablePagePreferences",
              JSON.stringify(updatedSiteCamerasTablePagePreferences)
            );
          } else {
            updatedSiteCamerasTablePagePreferences[siteInfo.id].rowsPerPage =
              parseInt(event.target.value, 10);
            updatedSiteCamerasTablePagePreferences[siteInfo.id].page = 0;
            localStorage.setItem(
              "siteCamerasTablePagePreferences",
              JSON.stringify(updatedSiteCamerasTablePagePreferences)
            );
          }
        }}
      />
    </Paper>
  );
};
