import React from "react";

export const handleCreateOutcomeText = (outcomeType: string) => {
  switch (outcomeType) {
    case "true_threat":
      return "True Threat";
    case "false_positive":
      return "False";
    case "true_non_threat":
      return "True Non-Threat";
    default:
      return "";
  }
};
