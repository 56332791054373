import { OrangeTextButton } from "../../common/OrangeTextButton";
import { DayField } from "./DayField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePickerField } from "./TimePickerField";
import { DatePickerField } from "./DatePickerField";
import { LocationDuskToDawnField } from "./LocationDuskToDawnField";
import { BufferTimeField } from "./BufferTimeField";
import { SwitchField } from "./SwitchField";
import { handleAddAnotherSchedule } from "./handleAddAnotherSchedule";
import { handleSubmitSchedule } from "./handleSubmitSchedule";
import React from "react";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Backdrop, Checkbox } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { handleCreateDaysArray } from "./handleCreateDaysArray";
import dayjs from "dayjs";
import ErrorBoundary from "../../../ErrorBoundary";
import { ScheduleGrid } from "../Sites/SiteAboutPage/ScheduleGrid";
import { handleConvertSchedulesToBooleanArrays } from "../Sites/SiteAboutPage/SiteAboutPageUtils/handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "../Sites/SiteAboutPage/SiteAboutPageUtils/handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../Sites/SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";
import { ActuateButton } from "../../common/ActuateButton";
import { EditScheduleTimesDialog } from "../Sites/SiteAboutPage/EditScheduleTimesDialog";
import { CalendarsTableAddSchedule } from "./CalendarsTableAddSchedule";
import { CalendarInfoDialog } from "./CalendarInfoDialog";

interface SubmitObjectData {
  customer: number;
  start_time: string | null;
  end_time: string | null;
  always_on: boolean;
  day_of_week: never[];
  is_override: boolean;
  enabled: boolean;
  buffer_time: string | number;
  location_dusk_dawn: string | null;
}

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const ScheduleScreen = ({
  setVisibleScreen,
  visibleScreen,
  setLargerScreen,
}: {
  setVisibleScreen: any;
  visibleScreen: any;
  setLargerScreen: any;
}) => {
  const [displayObject, setDisplayObject] = React.useState({
    start_time: dayjs("2022-04-17T07:00"),
    end_time: new Date("2018-01-01T00:00:00.000Z"),
    always_on: false,
    enabled: true,
    override_start_date: new Date().toString(),
    override_end_date: new Date().toString(),
    is_override: false,
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thur: false,
    fri: false,
    sat: false,
    location_dusk_dawn: "",
  });

  const [selectedCalendars, setSelectedCalendars] = React.useState([]);

  const [scheduleNumber, setscheduleNumber] = React.useState(1);

  const [selectAll, setSelectAll] = React.useState(false);

  const [submitObject, setSubmitObject] = React.useState<SubmitObjectData>({
    customer: Number(localStorage.siteId),
    start_time: "07:00:00",
    end_time: "19:00:00",
    always_on: false,
    day_of_week: [],
    is_override: false,
    enabled: true,
    location_dusk_dawn: null,
    buffer_time: 0,
  });

  const [submittedArray, setSubmittedArray] = React.useState<unknown[]>([]);

  const [disabledInputs, setDisabledInputs] = React.useState({
    start_time: false,
    end_time: false,
    always_on: false,
    override_start_date: true,
    override_end_date: true,
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thur: false,
    fri: false,
    sat: false,
    location_dusk_dawn: false,
    buffer_time: true,
    enabled: false,
    override_start_end_dates: false,
  });

  const [numberOnlyError, setNumberOnlyError] = React.useState({
    buffer_time: false,
  });

  const [disabledButtons, setDisabledButtons] = React.useState({
    save: false,
    addAdditionalSchedule: true,
    submit: true,
  });

  const [addScheduleError, setAddSchedulError] = React.useState(
    localStorage.addScheduleError
  );

  const locations = JSON.parse(localStorage.locations) || [];

  const locationIds = new Map();

  locations.forEach((element: any) => {
    locationIds.set(`${element.name}, ${element.region}`, element.id);
  });

  const navigate = useNavigate();

  const navigateSitesPage = () => {
    navigate(`/sites/aboutsite/${localStorage.siteId}`);
  };

  const [siteSchedules, setSiteSchedules] = React.useState([]);

  const [editTimeScheduleDialogOpen, setEditTimeScheduleDialogOpen] =
    React.useState(false);

  const [selectedDayInfo, setSelectedDayInfo] = React.useState({});

  const [timeRanges, setTimeRanges] = React.useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [grid, setGrid] = React.useState(
    handleConvertSchedulesToBooleanArrays([])
  );

  const [duskToDawn, setDuskToDawn] = React.useState(
    handleCreateDuskToDawn([])
  );

  const [duskToDawnLocation, setDuskToDawnLocation] = React.useState(
    handleCreateDuskToDawnLocationPlusBuffer([])
  );

  const [matchingSchedules, setMatchingSchedules] = React.useState<number[][]>(
    []
  );

  const calendarsData = JSON.parse(localStorage.calendarsData).results.filter(
    (calendar: any) => calendar.group.name === localStorage.groupSelected
  );

  const [calendarInfoDialogOpen, setCalendarInfoDialogOpen] =
    React.useState(false);

  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const [calendarEvents, setCalendarEvents] = React.useState([]);

  const [editingCalendar, setEditingCalendar] = React.useState(false);

  const [loadingEventData, setLoadingEventData] = React.useState(false);

  React.useEffect(() => {
    if (JSON.parse(localStorage.siteSchedule)) {
      setSubmittedArray(JSON.parse(localStorage.siteSchedule));
    }
  }, []);

  return (
    <ErrorBoundary>
      <div>
        {editTimeScheduleDialogOpen && (
          <EditScheduleTimesDialog
            allSiteSchedules={siteSchedules}
            siteInfo={{}}
            setAllSiteSchedules={setSiteSchedules}
            editTimeScheduleDialogOpen={editTimeScheduleDialogOpen}
            setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
            matchingSchedules={matchingSchedules}
            selectedDayInfo={selectedDayInfo}
            timeRanges={timeRanges}
            duskToDawnLocation={duskToDawnLocation}
            setBackdropLoadingScreenOn={() => {}}
            setActiveTab={() => {}}
            setEditMode={() => {}}
            setSiteSchedules={setSiteSchedules}
            setDeleteScheduleDialogOpen={() => {}}
            setSiteInfo={() => {}}
            setTimeRanges={setTimeRanges}
            setGrid={setGrid}
            setMatchingSchedules={setMatchingSchedules}
            navigate={navigate}
          />
        )}
        {addScheduleError && (
          <div className="absolute w-[600px] h-[50px]">
            <Alert
              severity="error"
              onClose={() => {
                setAddSchedulError(false);
                localStorage.removeItem("addScheduleError");
              }}
            >
              <p className="font-mulish font-bold text-[16px]">
                Problem adding Schedule. Please try again.
              </p>
            </Alert>
          </div>
        )}
        {calendarInfoDialogOpen && (
          <div className="absolute">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={calendarInfoDialogOpen}
            >
              <CalendarInfoDialog
                setCalendarInfoDialogOpen={setCalendarInfoDialogOpen}
                selectedRow={selectedRow}
                calendarEvents={calendarEvents}
                setCalendarEvents={setCalendarEvents}
                editingCalendar={editingCalendar}
                loadingEventData={loadingEventData}
              />
            </Backdrop>
          </div>
        )}
        <p
          className={
            "font-mulish absolute top-[70px] text-[#283E6D] right-[1070px] text-[21px]"
          }
        >
          Please create a schedule for this Site
        </p>
        <div className="pt-[120px] pl-[30px]">
          <ScheduleGrid
            editable={true}
            setSelectedDayInfo={setSelectedDayInfo}
            setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
            timeRanges={timeRanges}
            setTimeRanges={setTimeRanges}
            grid={grid}
            setGrid={setGrid}
            duskToDawn={duskToDawn}
            setDuskToDawn={setDuskToDawn}
            duskToDawnLocation={duskToDawnLocation}
            setDuskToDawnLocation={setDuskToDawnLocation}
            setMatchingSchedules={setMatchingSchedules}
            setScheduleChangesMade={() => {}}
            setAllSiteSchedules={setSiteSchedules}
          />
          {calendarsData.length > 0 && (
            <div>
              <p
                className={
                  "font-mulish font-bold text-[#283E6D] pt-[10px] text-[16px]"
                }
              >
                {`Calendars (optional)`}
              </p>
              <div className="pt-[10px]">
                <CalendarsTableAddSchedule
                  calendarsData={calendarsData}
                  setLoadingEventData={setLoadingEventData}
                  setSelectedRow={setSelectedRow}
                  setCalendarEvents={setCalendarEvents}
                  navigate={navigate}
                  setShowMoreDialogOpen={setCalendarInfoDialogOpen}
                  selectedCalendars={selectedCalendars}
                  setSelectedCalendars={setSelectedCalendars}
                />
              </div>
            </div>
          )}
        </div>
        <div className="absolute right-[50px] top-[70px]">
          <ActuateButton
            onClick={() => {
              setLargerScreen(false);
              handleSubmitSchedule(
                siteSchedules,
                setVisibleScreen,
                visibleScreen,
                setLargerScreen,
                navigate,
                navigateSitesPage,
                selectedCalendars
              );
              setVisibleScreen(
                <StatusNotificationScreen
                  styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
                  text={
                    <p>
                      We&apos;re submitting this information.
                      <br />
                      This might take a few seconds.
                    </p>
                  }
                />
              );
            }}
            text="Submit"
            disabled={false}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};
