import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { TextField } from "@mui/material";
import { addNotes } from "./AlertsUtils/addNotes";
import { NavigateFunction } from "react-router-dom";
import { addUnresolvedNotes } from "./AlertsUtils/addUnresolvedNotes";
import { handleCreateOutcomeText } from "./AlertsUtils/handleCreateOutcomeText";

export const NotesDialog = ({
  notesDialogOpen,
  setNotesDialogOpen,
  selectedRow,
  noteText,
  setNoteText,
  navigate,
  alertData,
  setAlertData,
  setSelectedRow,
}: {
  notesDialogOpen: boolean;
  setNotesDialogOpen: Dispatcher<boolean>;
  selectedRow: any;
  noteText: string;
  setNoteText: Dispatcher<string>;
  navigate: NavigateFunction;
  alertData: any;
  setAlertData: any;
  setSelectedRow: any;
}) => {
  const [editMode, setEditMode] = React.useState(false);

  return (
    <Dialog
      open={notesDialogOpen}
      maxWidth="xs"
      fullWidth={true}
      sx={{ height: 750 }}
    >
      {/* <DialogTitle id="notes-dialog-title">{"Notes"}</DialogTitle> */}
      <IconButton
        aria-label="close"
        onClick={() => {
          setNotesDialogOpen(false);
          setNoteText("");
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14, fontFamily: "Mulish" }}>
          Site Name
        </DialogContentText>
        <DialogContentText
          sx={{ fontSize: 18, fontFamily: "Mulish", color: "rgb(0 0 0)" }}
        >
          {selectedRow.customer_name}
        </DialogContentText>
        <br></br>
        <DialogContentText sx={{ fontSize: 14, fontFamily: "Mulish" }}>
          Camera
        </DialogContentText>
        <DialogContentText
          sx={{ fontSize: 18, fontFamily: "Mulish", color: "rgb(0 0 0)" }}
        >
          {selectedRow.camera_name}
        </DialogContentText>
        <br></br>
        <DialogContentText sx={{ fontSize: 14, fontFamily: "Mulish" }}>
          Outcome
        </DialogContentText>
        <DialogContentText
          sx={{ fontSize: 18, fontFamily: "Mulish", color: "rgb(0 0 0)" }}
        >
          {selectedRow.detection_type
            ? handleCreateOutcomeText(selectedRow.detection_type)
            : ""}
        </DialogContentText>
        <br></br>
        {!editMode && (
          <DialogContentText sx={{ fontSize: 14, fontFamily: "Mulish" }}>
            Notes
          </DialogContentText>
        )}
        {!editMode && (
          <DialogContentText
            sx={{
              fontSize: 18,
              fontFamily: "Mulish",
              color: "rgb(0 0 0)",
              maxWidth: 400,
            }}
          >
            {noteText
              ? noteText.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))
              : ""}
          </DialogContentText>
        )}
        {editMode && (
          <TextField
            onChange={(event) => {
              const input = event.target.value;
              if (input.length <= 1000) {
                setNoteText(input);
              }
            }}
            value={noteText}
            label="Notes"
            variant="outlined"
            multiline
            rows={3}
            sx={{ fontFamily: "Mulish", width: 400 }}
            helperText={noteText ? `${noteText.length}/1000` : "0/1000"}
          />
        )}
        <DialogActions>
          {!editMode && (
            <OrangeTextButton
              onClick={() => {
                setEditMode(true);
              }}
              text="Edit Note"
              disabled={false}
            />
          )}
          {editMode && (
            <OrangeTextButton
              onClick={() => {
                selectedRow.detection_type
                  ? addNotes(
                      selectedRow,
                      noteText,
                      setEditMode,
                      alertData,
                      setAlertData,
                      setSelectedRow,
                      navigate
                    )
                  : addUnresolvedNotes(
                      selectedRow,
                      noteText,
                      setEditMode,
                      alertData,
                      setAlertData,
                      setSelectedRow,
                      navigate
                    );
              }}
              text="Save Changes"
              disabled={false}
            />
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
