import { AuthProvider } from "./Components/utils/auth";
import { CookiesProvider } from "react-cookie";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Login } from "./Components/pages/Login";
import { Dashboard } from "./Components/pages/Dashboard/Dashboard";
import { Sites } from "./Components/pages/Sites/SitesPage/Sites";
import { AddSite } from "./Components/pages/AddSite/AddSite";
import { AddCamera } from "./Components/pages/AddCamera/AddCamera";
import { CameraAutoAdd } from "./Components/pages/CameraAutoAdd/CameraAutoAdd";
import { AddSchedule } from "./Components/pages/AddSchedule/AddSchedule";
import { RequireAuth } from "./Components/utils/RequireAuth";
import { Alerts } from "./Components/pages/Alerts/Alerts";
import { GroupUsers } from "./Components/pages/GroupUsers/GroupUsers";
import { Analytics } from "./Components/pages/Analytics/Analytics";
import { AnalyticsMirror } from "./Components/pages/Analytics/AnalyticsMirror";
import { User } from "./Components/pages/User";
import { Callback } from "./Components/pages/Callback";
import { TestPage } from "./Components/pages/TestPage";
import { EagleEyeCallback } from "./Components/pages/EagleEyeCallback";
import { SiteAbout } from "./Components/pages/Sites/SiteAboutPage/SiteAbout";
import { PageNotFound } from "./Components/pages/PageNotFound";
import { NoPermissionsPage } from "./Components/pages/NoPermissionsPage";
import { ProductRequirements } from "./Components/pages/ProductRequirements";
import { MFASetup } from "./Components/pages/MFASetup/MFASetup";
import { EditIgnoreZones } from "./Components/pages/EditIgnoreZones/EditIgnoreZones";
import { EditCamera } from "./Components/pages/EditCamera/EditCamera";
import { CameraCheck } from "./Components/pages/CameraCheck/CameraCheck";
import { Home } from "./Components/pages/Home/Home";
import { Investigation } from "./Components/pages/Investigation/Investigation";
import { Calendars } from "./Components/pages/Calendars/Calendars";
import "./index.css";
import { SMTPTab } from "./Components/pages/Sites/SiteAboutPage/SMTPTab";
// import { AlertsMirror } from "./Components/pages/AlertsCopy/AlertsMirror";
import AlertsMirror from "./Components/pages/Alerts/AlertsMirror";
import { HealthMonitoring } from "./Components/pages/HealthMonitoring/HealthMonitoring";
import { ActionLogs } from "./Components/pages/ActionLogs/ActionLogs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CameraCheckResultScreen } from "./Components/pages/CameraCheck/CameraCheckResultScreen";

const App = (packageJson) => {
  localStorage.setItem("packageJsonVersion", packageJson.packageJson.version);

  const [groupTrees, setGroupTrees] = React.useState([]);

  const [permissions, setPermissions] = React.useState({
    arm_disarm: false,
    add_cameras: false,
    edit_cameras: false,
    delete_cameras: false,
    manage_users: false,
  });

  const [navBarCollapsed, setNavBarCollapsed] = React.useState(false);

  const [cameraCheckFinsihedAlertOpen, setCameraCheckFinsihedAlertOpen] =
    React.useState(false);

  const [cameraCheckResultsDialogOpen, setCameraCheckResultsDialogOpen] =
    React.useState(false);

  const [goodCameras, setGoodCameras] = React.useState([]);

  const [connectivityCameras, setConnectivityCameras] = React.useState([]);

  const [lowResolutionCameras, setLowResolutionCameras] = React.useState([]);

  const [cameraCheckResultDownloadURL, setCameraCheckResultDownloadURL] =
    React.useState("");

  const navigate = useNavigate();

  // when a user attempts to go to any page before login save the page in local storage as redirect url
  React.useEffect(() => {
    if (!window.location.pathname.includes("callback")) {
      localStorage.setItem("redirectUrl", window.location.pathname);
    }
    if (!window.location.pathname.includes("callback")) {
      if (window.location.pathname.includes("login")) {
        localStorage.setItem("redirectUrl", "/dashboard");
      }
      //else set the local storage redirect url to whatever is after the first backslash in the url. So if the url pathname was "http://localhost:1234/sites/aboutsite/14483" then it would return "/sites/aboutsite/14483"
      else {
        localStorage.setItem("redirectUrl", window.location.pathname);
      }
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.loggedIn === "true" && localStorage.userInfo) {
      const userInfo = JSON.parse(localStorage.userInfo);

      setPermissions({
        arm_disarm: userInfo.permission.arm_disarm,
        add_cameras: userInfo.permission.add_cameras,
        edit_cameras: userInfo.permission.edit_cameras,
        delete_cameras: userInfo.permission.delete_cameras,
        manage_users: userInfo.permission.manage_users,
      });
    }
  }, []);

  const cameraCheckFinsihedAlert = (
    <>
      {cameraCheckFinsihedAlertOpen && (
        <div className="fixed top-0 left-0 w-full z-50">
          <Alert
            severity="success"
            onClose={() => setCameraCheckFinsihedAlertOpen(false)}
          >
            <p className="font-mulish">
              Camera check finished
              <Button
                type="button"
                onClick={() => {
                  localStorage.setItem("cameraCheckAlertOpen", "false");
                  setCameraCheckFinsihedAlertOpen(false);
                  setCameraCheckResultsDialogOpen(true);
                }}
                disabled={false}
                size="small"
              >
                CLICK HERE
              </Button>
              to see results.
            </p>
          </Alert>
        </div>
      )}
    </>
  );

  const cameraCheckResultsDialog = (
    <Dialog
      open={cameraCheckResultsDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Camera Check Results"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setCameraCheckResultsDialogOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <div className="w-[1000px]">
          <CameraCheckResultScreen
            goodCameras={goodCameras}
            connectivityCameras={connectivityCameras}
            lowResolutionCameras={lowResolutionCameras}
            cameraCheckResultDownloadURL={cameraCheckResultDownloadURL}
            navigate={navigate}
          />
        </div>
      </DialogContent>
    </Dialog>
  );

  return (
    <AuthProvider>
      <CookiesProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {/* {<a href="https://releasenotes.actuateui.net" className="rn-badge">Updates</a>} */}
                <Home
                  permissions={permissions}
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <Dashboard
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route path="login" element={<Login />} />
          <Route
            path="analyticsmirror"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <Analytics
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="alertsmirror"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <AlertsMirror
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />

          <Route
            path="sites"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <Sites
                  groupTrees={groupTrees}
                  setGroupTrees={setGroupTrees}
                  permissions={permissions}
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="investigation"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <Investigation
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          {/* <Route
            path="test"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <TestPage
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          /> */}
          <Route
            path="sites/addsite"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.add_cameras ? (
                  <AddSite
                    groupTrees={groupTrees}
                    setGroupTrees={setGroupTrees}
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="/smtp"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <SMTPTab
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="sites/cameracheck"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.add_cameras ? (
                  <CameraCheck
                    groupTrees={groupTrees}
                    setGroupTrees={setGroupTrees}
                    setCameraCheckFinsihedAlertOpen={
                      setCameraCheckFinsihedAlertOpen
                    }
                    setGoodCameras={setGoodCameras}
                    setConnectivityCameras={setConnectivityCameras}
                    setLowResolutionCameras={setLowResolutionCameras}
                    setCameraCheckResultDownloadURL={
                      setCameraCheckResultDownloadURL
                    }
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="sites/addCamera"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.add_cameras ? (
                  <AddCamera
                    groupTrees={groupTrees}
                    setGroupTrees={setGroupTrees}
                    setCameraCheckFinsihedAlertOpen={
                      setCameraCheckFinsihedAlertOpen
                    }
                    setGoodCameras={setGoodCameras}
                    setConnectivityCameras={setConnectivityCameras}
                    setLowResolutionCameras={setLowResolutionCameras}
                    setCameraCheckResultDownloadURL={
                      setCameraCheckResultDownloadURL
                    }
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="sites/autoaddcamera"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.add_cameras ? (
                  <CameraAutoAdd
                    groupTrees={groupTrees}
                    setGroupTrees={setGroupTrees}
                    setCameraCheckFinsihedAlertOpen={
                      setCameraCheckFinsihedAlertOpen
                    }
                    setGoodCameras={setGoodCameras}
                    setConnectivityCameras={setConnectivityCameras}
                    setLowResolutionCameras={setLowResolutionCameras}
                    setCameraCheckResultDownloadURL={
                      setCameraCheckResultDownloadURL
                    }
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />

          <Route
            path="sites/addschedule"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.add_cameras ? (
                  <AddSchedule
                    groupTrees={groupTrees}
                    setGroupTrees={setGroupTrees}
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="sites/addschedulesecret"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.add_cameras ? (
                  <AddSchedule
                    groupTrees={groupTrees}
                    setGroupTrees={setGroupTrees}
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="sites/aboutsite/:siteid"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <SiteAbout
                  permissions={permissions}
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="sites/aboutsitesecret/:siteid"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <SiteAbout
                  permissions={permissions}
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="sites/:siteid/editcamera/:cameraid/editignorezones"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.edit_cameras ? (
                  <EditIgnoreZones
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="sites/:siteid/editcamera/:cameraid"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <EditCamera
                  permissions={permissions}
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="calendars"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.add_cameras ? (
                  <Calendars
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="actionlogs/:siteid"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <ActionLogs
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="alerts&:alertsFilters"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <AlertsMirror
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="alerts"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <AlertsMirror
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="analytics"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <Analytics
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="user"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <User
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="groupuser"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                {permissions.manage_users ? (
                  <GroupUsers
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                ) : (
                  <NoPermissionsPage
                    navBarCollapsed={navBarCollapsed}
                    setNavBarCollapsed={setNavBarCollapsed}
                  />
                )}
              </RequireAuth>
            }
          />
          <Route
            path="health"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <HealthMonitoring
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="productrequirements"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <ProductRequirements
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
          <Route
            path="callback"
            element={
              <Callback
                setGroupTrees={setGroupTrees}
                setPermissions={setPermissions}
              />
            }
          />
          <Route
            path="eecallback"
            element={
              <RequireAuth>
                <EagleEyeCallback />
              </RequireAuth>
            }
          />
          <Route
            path="MFA"
            element={
              // <RequireAuth>
              <MFASetup />
              // </RequireAuth>
            }
          />
          <Route
            path="*"
            element={
              <RequireAuth>
                {cameraCheckFinsihedAlert}
                {cameraCheckResultsDialog}
                <PageNotFound
                  navBarCollapsed={navBarCollapsed}
                  setNavBarCollapsed={setNavBarCollapsed}
                />
              </RequireAuth>
            }
          />
        </Routes>
      </CookiesProvider>
    </AuthProvider>
  );
};

export default App;
