import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "./createApi";
import { NavigateFunction } from "react-router-dom";
import { lchown } from "fs";
import { saveUserTimezone } from "./saveUserTimezone";

const apiResources = [
  [`user/info/`, "userInfo"],
  [`timezone/`, "timeZoneArray"],
  [`option/product/`, "products"],
  [`integration_type/onboarding/`, "integrationTypes"],
  [`integration_type/`, "integrationTypesNonOnboarding"],
  [`group/?page_size=2027`, "groupArray"],
  [`schedule_location/`, "locations"],
  [`group/?page_size=10000`, "parentGroups"],
  // [`analytics/data_usage/?date_range=last_24_hours`, "top5DataUsage"],
  [`analytics/date_range_choices/`, "rangeChoices"],
  [`group/?parent_account=true&page_size=10000`, "parentAccounts"],
  [`customer/default_alarm_option/`, "alarmOptions"],
  [`immix_credential/?user_type=ailink`, "aiLinkUsers"],
  [`immix_credential/?user_type=sentinel`, "sentinelUsers"],
  [`product_requirements`, "productRequirements"],
  [`option/?parent__value=camera_view`, "cameraViewOptions"],
  [`webhook/event_types/`, "webHookEventTypeOptions"],
  [`calendar/`, "calendarsData"],
];

async function getResource(resource: string[]) {
  const AxiosUI = createApi(``);
  localStorage.setItem("navBarCollapsed", "true");
  localStorage.setItem("siteSchedule", "false");
  localStorage.setItem("selectedProducts", "false");
  localStorage.setItem("siteInfo", "false");
  localStorage.setItem("ignoreZones", "false");
  localStorage.setItem("cameraNumber", "1");
  localStorage.setItem("currentCamera", "");
  localStorage.setItem("preview_url", "false");
  localStorage.setItem("clickedFromSiteAboutPage", "false");
  localStorage.setItem("siteDeletedAlert", "false");
  localStorage.setItem("syncNeeded", "false");
  localStorage.setItem("sitesTablePage", "0");
  localStorage.setItem("sitesRowsPerPage", "10");
  localStorage.setItem("siteGroupListScrollTop", "0");
  localStorage.setItem("level0Click", "true");
  localStorage.setItem("siteAboutScrollTop", "0");
  localStorage.setItem("groupNameChange", "true");
  localStorage.setItem("dashboardTotal", "false");
  localStorage.setItem("falsePostiveData", "false");
  localStorage.setItem("camerasErrorInfo", "false");
  localStorage.setItem("camerasWarningInfo", "false");
  localStorage.setItem("rebootSite", "false");
  localStorage.setItem("immixAlertsSite", "false");
  localStorage.setItem("sentinelAlertsSite", "false");
  localStorage.setItem("sureviewAlertsSite", "false");
  localStorage.setItem("SMTPSite", "false");
  localStorage.setItem("noAutoAddCamerasDialogOpen", "false");
  localStorage.setItem("currentSiteBreadcrumbs", JSON.stringify([]));
  localStorage.setItem("top5DataUsage", JSON.stringify([]));
  localStorage.setItem("groupTrees", JSON.stringify([]));
  localStorage.setItem("percetageOfMotionData", JSON.stringify([]));
  localStorage.setItem("navigatedToAnalyticsFromSitesPage", "false");
  localStorage.setItem("filtersSavedClicked", "false");
  localStorage.setItem("alertsIconOpen", JSON.stringify(""));
  localStorage.setItem("groupID", "1");
  localStorage.setItem("refresh_interval", JSON.stringify(30000));
  localStorage.setItem("alertsIconOpen", "");
  localStorage.setItem("newCalendarName", "");
  localStorage.setItem("tokenRefreshing", "false");
  localStorage.setItem("alertsAllData", JSON.stringify([]));
  localStorage.setItem("alertsErrorsData", JSON.stringify([]));
  localStorage.setItem("alertsWarningsData", JSON.stringify([]));
  localStorage.setItem("alertsResolvedData", JSON.stringify([]));
  localStorage.setItem("alertsPendingData", JSON.stringify([]));
  localStorage.setItem("editCamerActiveTab", "one");
  localStorage.setItem("sortedHMAlertsRows", JSON.stringify([]));
  localStorage.setItem("healthcheckOnly", "false");
  localStorage.setItem("queryString", JSON.stringify(``));
  localStorage.setItem("moreThan100InvestigationsData", "false");
  localStorage.setItem("last_timestamp", "false");
  localStorage.setItem("showWebHooksOnboardingScreen", JSON.stringify(false));
  localStorage.setItem("last_file_id_sentinal", "");
  localStorage.setItem("last_timestamp_sentinal", "");
  localStorage.setItem("last_file_id_ailink", "");
  localStorage.setItem("last_timestamp_ailink", "");
  localStorage.setItem("armButtonCheckURL", "");
  localStorage.setItem("siteCamerasTablePagePreferences", JSON.stringify({}));
  localStorage.setItem("cameraCheckAlertOpen", "false");
  localStorage.setItem("selectedCHMAlertsArray", JSON.stringify([]));
  localStorage.setItem("selectedRowAlerts", "{}");
  localStorage.setItem("selectedGroupOrSiteId", "0");
  localStorage.setItem("selectedItemSiteGroupList", JSON.stringify({}));
  localStorage.setItem("schedulesIndex", "0");
  localStorage.setItem(
    "immixAlertPostObject",
    JSON.stringify({
      id: -1,
      immix_server: "",
      immix_port: "",
    })
  );
  localStorage.setItem(
    "sentinelAlertPostObject",
    JSON.stringify({
      sentinel_server: "",
      sentinel_site_id: "",
      sentinel_device_address: "",
      draw_ignore_zones: false,
    })
  );
  localStorage.setItem(
    "storedSelectedIndex",
    JSON.stringify({
      level1: "",
      level2: "",
      level3: "",
      level4: "",
      level5: "",
      level6: "",
    })
  );
  saveUserTimezone();
  const { data } = await AxiosUI.get(resource[0]);
  if (resource[1] === "parentAccounts") {
    localStorage.setItem("groupID", data.results[0].parent);
  }
  if (resource[1] === "userInfo") {
    localStorage.setItem("username", data.username);
    localStorage.setItem("first_name", data.first_name);
    localStorage.setItem("last_name", data.last_name);
    localStorage.setItem("userInfo", JSON.stringify(data));
  } else if (resource[1] === "timeZoneArray") {
    const tzArray: string[] = [];
    data.forEach((item: { value: string }) => {
      tzArray.push(item.value);
    });
    localStorage.setItem(`${resource[1]}`, JSON.stringify(tzArray));
  } else if (
    resource[1] === "products" ||
    resource[1] === "integrationTypes" ||
    resource[1] === "integrationTypesNonOnboarding" ||
    resource[1] === "locations" ||
    resource[1] === "rangeChoices" ||
    resource[1] === "alertsByParentGroups" ||
    resource[1] === "alarmOptions" ||
    resource[1] === "aiLinkUsers" ||
    resource[1] === "sentinelUsers" ||
    resource[1] === "productRequirements" ||
    resource[1] === "cameraViewOptions" ||
    resource[1] === "webHookEventTypeOptions" ||
    resource[1] === "calendarsData"
  ) {
    localStorage.setItem(`${resource[1]}`, JSON.stringify(data));
    if (resource[1] === "aiLinkUsers") {
      localStorage.setItem(`aiLinkUserName`, data[0].username);
    }
    if (resource[1] === "sentinelUsers") {
      localStorage.setItem(`sentinelUserName`, data[0].username);
    }
  } else if (resource[1] === "top5DataUsage") {
    const updatedData = data;
    for (const key in updatedData) {
      const updatedKey: any = updatedData[key];
      updatedKey.abreviated_name = `${updatedKey.display_name.slice(0, 7)}...`;
      if (updatedKey.kbs) {
        const updatedKBS = Math.round(updatedKey.kbs / 10) / 100;
        updatedKey.kbs = updatedKBS;
      }
    }
    localStorage.setItem(`${resource[1]}`, JSON.stringify(updatedData));
  } else {
    localStorage.setItem(`${resource[1]}`, JSON.stringify(data.results));
  }
}

export async function getAllResources() {
  const apiPromises = apiResources.map(getResource);
  await Promise.all(apiPromises).catch((response: any) => {
    if (response.response) {
      if (response.response.data) {
        if (response.response.data.detail === "Pending MFA Setup") {
          localStorage.setItem("getMFA", "true");
        }
      }
    }
  });
}
