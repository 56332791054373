import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { handleEditCameraSaveChangesButtonClicked } from "./EditCameraUtils/handleEditCameraSaveChangesButtonClicked";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const EditCameraSaveChangesButton = ({
  setEditMode,
  clickedTab,
  setActiveTab,
  siteInfo,
  cameraGeneralInfo,
  setBackdropLoadingScreenOn,
  generalInfoChangesMade,
  patchObject,
  setGeneralInfoChangesMade,
  setPatchObject,
  setCameraGeneralInfo,
  cameraGeneralInfoBackup,
  setCameraGeneralInfoBackup,
  immixAlerts,
  activeChangeMade,
  sureviewAlerts,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  setAddImmixAlertDialogOpen,
  setAddSureviewAlertDialogOpen,
  activeProducts,
  setStreamsObject,
  setActiveProducts,
  setImmixAlertsOnCamera,
  setSureViewAlertsOnCamera,
  setSendingImmixTest,
  setImmixTestSuccessDialogOpen,
  setImmixTestFailDialogOpen,
  setSrcImages,
  setCameraInfoRow,
  width,
  permissions,
  cameraWebhooksChangesMade,
  cameraWebhooksPatchObject,
  recipientsConfiguredData,
  titlesArray,
  setRecipientsConfiguredData,
  setTitlesArray,
  actionRulesData,
  setActionRulesData,
  actionRulesTitlesArray,
  setActionRulesTitlesArray,
  applicationsConfiguredData,
  axisFinished,
  timeTablesData,
  setTimeTablesData,
  iPNotifyData,
  setIPNotifyData,
  actionHandlerData,
  setActionHandlerData,
  cameraWebhookData,
  setCameraWebhookData,
  setCameraWebhooksPatchObject,
  setCameraWebhooksChangesMade,
  productsData,
  setProductsData,
  allTabsOpen,
  setAllTabsOpen,
  backupProductsData,
  setBackupProductsData,
  setSentinelAlertsOnCamera,
  sentinelAlerts,
  setAddSentinelAlertDialogOpen,
  setBoldAlertsOnCamera,
  boldAlerts,
  setAddBoldAlertDialogOpen,
  setPatriotAlertsOnCamera,
  patriotAlerts,
  setAddPatriotAlertDialogOpen,
  setUSMonitoringAlertsOnCamera,
  uSMonitoringAlerts,
  setAddUSMonitoringAlertDialogOpen,
  setSoftguardAlertsOnCamera,
  softguardAlerts,
  setAddSoftguardAlertDialogOpen,
  setSoftguardAlerts,
  streamParametersData,
  streamParametersChangesMade,
  setStreamParametersData,
  setStreamParametersChangesMade,
  streamParametersPatchObject,
  setAddStreamWebhookDialogOpen,
  setEditStreamWebhookDialogOpen,
  siteSchedules
}: {
  setEditMode: any;
  clickedTab: any;
  setActiveTab: any;
  siteInfo: any;
  cameraGeneralInfo: any;
  setBackdropLoadingScreenOn: any;
  generalInfoChangesMade: any;
  patchObject: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setCameraGeneralInfo: any;
  cameraGeneralInfoBackup: any;
  setCameraGeneralInfoBackup: any;
  immixAlerts: any;
  activeChangeMade: any;
  sureviewAlerts: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  setAddImmixAlertDialogOpen: any;
  setAddSureviewAlertDialogOpen: any;
  activeProducts: any;
  setStreamsObject: any;
  setActiveProducts: any;
  setImmixAlertsOnCamera: any;
  setSureViewAlertsOnCamera: any;
  setSendingImmixTest: any;
  setImmixTestSuccessDialogOpen: any;
  setImmixTestFailDialogOpen: any;
  setSrcImages: any;
  setCameraInfoRow: any;
  width: any;
  permissions: any;
  cameraWebhooksChangesMade: boolean;
  cameraWebhooksPatchObject: any;
  recipientsConfiguredData: any;
  titlesArray: any;
  setRecipientsConfiguredData: any;
  setTitlesArray: any;
  actionRulesData: any;
  setActionRulesData: any;
  actionRulesTitlesArray: any;
  setActionRulesTitlesArray: any;
  applicationsConfiguredData: any;
  axisFinished: any;
  timeTablesData: any;
  setTimeTablesData: any;
  iPNotifyData: any;
  setIPNotifyData: any;
  actionHandlerData: any;
  setActionHandlerData: any;
  cameraWebhookData: any;
  setCameraWebhookData: any;
  setCameraWebhooksPatchObject: any;
  setCameraWebhooksChangesMade: Dispatcher<boolean>;
  productsData: any;
  setProductsData: Dispatcher<any>;
  allTabsOpen: boolean;
  setAllTabsOpen: Dispatcher<boolean>;
  backupProductsData: any;
  setBackupProductsData: Dispatcher<any>;
  setSentinelAlertsOnCamera: Dispatcher<any>;
  sentinelAlerts: any;
  setAddSentinelAlertDialogOpen: Dispatcher<boolean>;
  setBoldAlertsOnCamera: Dispatcher<any>;
  boldAlerts: any;
  setAddBoldAlertDialogOpen: Dispatcher<boolean>;
  setPatriotAlertsOnCamera: Dispatcher<any>;
  patriotAlerts: any;
  setAddPatriotAlertDialogOpen: Dispatcher<boolean>;
  setUSMonitoringAlertsOnCamera: Dispatcher<any>;
  uSMonitoringAlerts: any;
  setAddUSMonitoringAlertDialogOpen: Dispatcher<boolean>;
  setSoftguardAlertsOnCamera: Dispatcher<any>;
  softguardAlerts: any;
  setAddSoftguardAlertDialogOpen: Dispatcher<boolean>;
  setSoftguardAlerts: any;
  streamParametersData: any;
  streamParametersChangesMade: any;
  setStreamParametersData: any;
  setStreamParametersChangesMade: any;
  streamParametersPatchObject: any;
  setAddStreamWebhookDialogOpen: any;
  setEditStreamWebhookDialogOpen: any;
  siteSchedules: any;
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <OrangeTextButton
        onClick={() => {
          handleEditCameraSaveChangesButtonClicked(
            setEditMode,
            clickedTab,
            setActiveTab,
            siteInfo,
            cameraGeneralInfo,
            setBackdropLoadingScreenOn,
            generalInfoChangesMade,
            patchObject,
            setGeneralInfoChangesMade,
            setPatchObject,
            navigate,
            setCameraGeneralInfo,
            cameraGeneralInfoBackup,
            setCameraGeneralInfoBackup,
            immixAlerts,
            activeChangeMade,
            sureviewAlerts,
            setIgnoreZonesBreadCrumbs,
            handleSitesBreadcrumbClick,
            handleSingularSiteBreadcrumbClick,
            setAddImmixAlertDialogOpen,
            setAddSureviewAlertDialogOpen,
            activeProducts,
            setStreamsObject,
            setActiveProducts,
            setImmixAlertsOnCamera,
            setSureViewAlertsOnCamera,
            setSendingImmixTest,
            setImmixTestSuccessDialogOpen,
            setImmixTestFailDialogOpen,
            setSrcImages,
            setCameraInfoRow,
            width,
            permissions,
            cameraWebhooksChangesMade,
            cameraWebhooksPatchObject,
            recipientsConfiguredData,
            titlesArray,
            setRecipientsConfiguredData,
            setTitlesArray,
            actionRulesData,
            setActionRulesData,
            actionRulesTitlesArray,
            setActionRulesTitlesArray,
            applicationsConfiguredData,
            axisFinished,
            timeTablesData,
            setTimeTablesData,
            iPNotifyData,
            setIPNotifyData,
            actionHandlerData,
            setActionHandlerData,
            cameraWebhookData,
            setCameraWebhookData,
            setCameraWebhooksPatchObject,
            setCameraWebhooksChangesMade,
            productsData,
            setProductsData,
            allTabsOpen,
            setAllTabsOpen,
            backupProductsData,
            setBackupProductsData,
            setSentinelAlertsOnCamera,
            sentinelAlerts,
            setAddSentinelAlertDialogOpen,
            setBoldAlertsOnCamera,
            boldAlerts,
            setAddBoldAlertDialogOpen,
            setPatriotAlertsOnCamera,
            patriotAlerts,
            setAddPatriotAlertDialogOpen,
            setUSMonitoringAlertsOnCamera,
            uSMonitoringAlerts,
            setAddUSMonitoringAlertDialogOpen,
            setSoftguardAlertsOnCamera,
            softguardAlerts,
            setAddSoftguardAlertDialogOpen,
            setSoftguardAlerts,
            streamParametersData,
            streamParametersChangesMade,
            setStreamParametersData,
            setStreamParametersChangesMade,
            streamParametersPatchObject,
            setAddStreamWebhookDialogOpen,
            setEditStreamWebhookDialogOpen,
            siteSchedules
          );
        }}
        text="SAVE CHANGES"
        disabled={false}
      />
    </div>
  );
};
