export const setEndDate = (
  newValue: any,
  userFilters: any,
  setUserFilters: any
) => {
  if (!userFilters.start_date) {
    setUserFilters((previousState: any) => {
      return { ...previousState, end_date: newValue };
    });
  }
  if (userFilters.start_date) {
    if (newValue >= userFilters.start_date) {
      setUserFilters((previousState: any) => {
        return { ...previousState, end_date: newValue };
      });
    }
    if (newValue < userFilters.start_date) {
      alert("End date cannot be before start date");
    }
  }
};
